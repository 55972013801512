/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-25 10:27:26
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-25 15:52:03
 */
import DatapackageDetail from './DatapackageDetail.vue'
export default DatapackageDetail
