/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-25 10:27:17
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 17:08:18
 */
import EnumMap from '@/plugins/enumMap'
export default {
  name: 'DatapackageDetail',
  data () {
    return {
      form: {
        acitve_list: [],
        compose_type: '',
        data_type_list: [],
        id: '',
        name: '',
        remove_list: [],
        source_type: ''
      }
    }
  },
  methods: {
    async init (id) {
      const data = await this.$wPost('/app/datapackage/diy/get.do', { id })
      this.form = Object.assign({}, this.form, data)
    },
    edit () {
      this.$router.push({ path: '/app/datapackage' })
    },
    previewImg (file, index) {
      let url = ''
      if (file.response) {
        url = file.response.data
      } else {
        url = file.url
      }
      window.open(url)
    },
    activityStatus (val) {
      const name = new EnumMap('package_diy_source').mapping(val)
      return name
    },
    dataTypeList (val) {
      const name = new EnumMap('package_data_type').mapping(val)
      return name
    }
  },
  mounted () {
    const id = this.$route.params.id - 0
    this.init(id)
  }
}
